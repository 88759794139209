import axios from "axios";

const DEV_MODE = false;

const http = axios.create({
  baseURL: DEV_MODE
    ? "http://localhost:3000/"
    : "https://vizzua-api.andersu.dev/",
  timeout: 15000,
  headers: {
    Accept: "application/json",
  },
  validateStatus: function () {
    return true;
  },
});

export default http;
