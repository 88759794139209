import HomePage from "./pages/home";
import { Routes, Route } from "react-router-dom";
import ServicosPage from "./pages/services";
import AgendamentoPage from "./pages/schedule";
import ResumoAgendamentoPage from "./pages/schedule-summary";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/servicos" element={<ServicosPage />} />
      <Route path="/agendamento" element={<AgendamentoPage />} />
      <Route path="/resumo-agendamento" element={<ResumoAgendamentoPage />} />
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
}
