import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SiteRepository from "../repositories/site_repository";
import queryString from "query-string";
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import { useToast } from "@chakra-ui/react";
export default function ResumeSchedulePage() {
  const Navigate = useNavigate();
  const toast = useToast();
  const [schedule, setSchedule] = useState(null);
  const [url, setUrl] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetchSchedule();
  }, []);

  const cancelSchedule = () => {
    const qs = queryString.parse(location.search);
    setUrl(window.location.href);
    var params = {
      id: qs.id,
    };

    SiteRepository.cancelSchedule({
      params,
    }).then((res) => {
      if (res.status === 200) {
        toast({
          description: "Cancelado com sucesso",
          status: "success",
        });
        Navigate(`/`);
      }
    });
  };

  const fetchSchedule = () => {
    const qs = queryString.parse(location.search);
    setUrl(window.location.href);

    console.log(url);
    console.log(qs);
    var params = {
      id: qs.id,
    };

    SiteRepository.consultarSchedule({
      params,
    }).then((res) => {
      if (res.status === 200) {
        setSchedule(res.data);
      }
    });
  };

  if (!schedule) {
    return <></>;
  }

  return (
    <Box align="center" h="100vh" backgroundColor="#5D34BE">
      <Flex
        align="center"
        direction="column"
        maxW="500px"
        px="2"
        h="full"
        pt="2"
        color="white"
      >
        <Heading size="lg" mb="2">
          Agendamento Realizado
        </Heading>
        <Text>
          Sugerimos que o(a) sr(a) faça um print ou anote as informações.
        </Text>
        <Flex
          align="start"
          w="full"
          maxW="420px"
          px="10px"
          mt="5"
          direction="column"
          textAlign="start"
        >
          <Text fontSize="sm" fontWeight="semibold">
            Quando
          </Text>

          <Text fontSize="sm">
            {format(
              new Date(schedule.date.replace("Z", "")),
              "dd MMM yyyy - HH:mm",
              {
                locale: ptBR,
              }
            )}
          </Text>
          <Text fontSize="sm" fontWeight="semibold">
            Serviço
          </Text>
          <Text fontSize="sm">{schedule.service_name}</Text>
          <Text fontSize="sm" fontWeight="semibold">
            Onde
          </Text>
          <Text fontSize="sm" textAlign="start">
            {schedule.user_address}
          </Text>
          <Text fontSize="sm" fontWeight="semibold">
            Valor a ser pago{""}
          </Text>
          <Text fontSize="sm">R$ {schedule.service_price}</Text>
          <Text fontSize="sm" fontWeight="semibold">
            Link do agendamento
          </Text>
          <Text fontSize="sm"> {url}</Text>
        </Flex>

        {schedule.canceled ? (
          <Text mt="2" fontSize="30PX" fontWeight="semibold">
            CANCELADO
          </Text>
        ) : (
          <Button
            mt="2"
            colorScheme="red"
            onClick={() => {
              cancelSchedule();
            }}
          >
            Cancelar
          </Button>
        )}

        <Flex
          align="center"
          w="full"
          maxW="420px"
          px="5px"
          mt="10"
          direction="column"
        >
          <Text fontSize="sm" fontWeight="semibold">
            Baixe o app e acompanhe seus agendamentos
          </Text>
          <Image
            mt="2"
            src="https://logodownload.org/wp-content/uploads/2017/04/disponivel-google-play-badge-4.png"
            h="60px"
            cursor="pointer"
            onClick={() => {
              window.location.replace(
                "https://play.google.com/store/apps/details?id=com.elevii.vizzua.users"
              );
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
