import { extendTheme } from "@chakra-ui/react";

const theme = {
  colors: {
    primary: {
      900: "#693FF3",
      800: "#693FF3",
      700: "#693FF3",
      600: "#693FF3",
      500: "#693FF3",
      400: "#693FF3",
      300: "#693FF3",
    },
  },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },
};

export default extendTheme(theme);
