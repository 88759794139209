import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { MdStarRate } from "react-icons/md";
import SiteRepository from "../repositories/site_repository";
import { useState, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

export default function ServicosPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const qs = queryString.parse(location.search);
    const id = qs.id;

    var params = {
      business_id: id,
    };

    SiteRepository.consultarBusinessServices({
      params,
    }).then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });
  }, []);

  if (data.length === 0) {
    return <></>;
  }

  return (
    <Box align="center" h="100vh" backgroundColor="#5D34BE">
      <Flex
        align="center"
        direction="column"
        maxW="500px"
        px="2"
        h="full"
        pt="2"
        w="100%"
      >
        <Box
          h="200px"
          backgroundColor="white"
          borderRadius="8"
          mt="5"
          mb="2"
          w="100%"
        >
          <Image
            h="100%"
            w="full"
            objectFit="cover"
            borderRadius="8"
            src={
              data[0].business_id.photo_source ??
              "https://i.imgur.com/99HaG7b.png"
            }
          />
        </Box>
        <Flex
          mt="2"
          px="2"
          color="white"
          direction="row"
          align="start"
          w="full"
          justifyContent="space-between"
        >
          <Flex direction="column" align="start">
            <Text fontWeight="semibold" fontSize="lg">
              {data[0].business_id.name}
            </Text>
            <Text fontSize="sm" noOfLines="2" textAlign="start">
              {data[0].business_id.full_address}
            </Text>
          </Flex>

          <Flex direction="row" justifyContent="center" fontWeight="bold">
            <MdStarRate size="20px" color="yellow" />
            <Text>5.0</Text>
          </Flex>
        </Flex>
        <Flex direction="column" w="full">
          {data.map((service, index, arr) => {
            return (
              <Box
                backgroundColor="white"
                borderRadius="8px"
                w="full"
                mt="4"
                p="4"
              >
                <Flex
                  direction="row"
                  w="full"
                  justify="space-between"
                  align="center"
                >
                  <Flex
                    direction="column"
                    align="start"
                    textAlign="start"
                    w="70%"
                  >
                    <Text
                      pt="1"
                      fontWeight="semibold"
                      fontSize="sm"
                      noOfLines="1"
                    >
                      {service.name}
                    </Text>
                    <Text fontSize="10px" noOfLines="2">
                      {service.description}
                    </Text>
                    <Text fontSize="10px" noOfLines="2">
                      {"R$ " +
                        service.price +
                        " - " +
                        service.duration +
                        " min"}
                    </Text>
                  </Flex>
                  <Button
                    colorScheme="purple"
                    size="md"
                    fontSize="sm"
                    onClick={() => {
                      navigate(
                        `/agendamento?id=${service._id}&business_id=${service.business_id._id}`
                      );
                    }}
                  >
                    Agendar
                  </Button>
                </Flex>
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
}
