import logo from "../assets/logo.png";
import SiteRepository from "../repositories/site_repository";
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MdSearch } from "react-icons/md";

export default function HomePage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        console.log("ok");
        if (location) getNerbyBusiness(location);
      });
    }
  }, []);

  const getNerbyBusiness = async (location) => {
    const { latitude, longitude } = location.coords;

    var params = {
      latitude: latitude,
      longitude: longitude,
    };
    SiteRepository.consultarNearbyBusiness({ params }).then((res) => {
      console.log(res);
      if (res.status === 200) setData(res.data);
    });
  };

  return (
    <Box align="center" h="100vh" backgroundColor="#5D34BE">
      <Flex
        align="center"
        direction="column"
        maxW="500px"
        px="2"
        h="full"
        pt="2"
      >
        <Image boxSize="120px" src={logo} alt="logo" />
        <Heading color="white" size="lg" mb="10">
          Vizzua - Agenda online e marcação
        </Heading>
        {/* <Flex direction="row" w="full" my="2" align="center">
          <Input
            w="full"
            borderRadius="8px"
            placeholder="Pesquisar por:"
            size="lg"
            color="white"
            mr="5"
          />
          <MdSearch cursor="pointer" color="white" size="35px" align="center" />
        </Flex> */}

        <Flex direction="column">
          {data.map((business, index, arr) => {
            return (
              <Box
                backgroundColor="white"
                borderRadius="8px"
                w="full"
                mt="4"
                p="4"
              >
                <Flex
                  direction="row"
                  w="full"
                  justify="space-between"
                  align="center"
                >
                  <Image
                    w="45px"
                    h="45px"
                    borderRadius="4px"
                    mr="2"
                    src={
                      business.photo_source ??
                      "http://dcminfo.com.br/images/sem_foto.png"
                    }
                  />
                  <Flex
                    direction="column"
                    align="start"
                    textAlign="start"
                    w="70%"
                  >
                    <Badge colorScheme="green" size="xs">
                      {business.at_home ? "Domicílio" : "Em local"}
                    </Badge>
                    <Text
                      pt="1"
                      fontWeight="semibold"
                      fontSize="sm"
                      noOfLines="1"
                    >
                      {business.name}
                    </Text>
                    <Text fontSize="10px" noOfLines="2">
                      {business.full_address}
                    </Text>
                  </Flex>
                  <Button
                    colorScheme="purple"
                    size="md"
                    fontSize="sm"
                    onClick={() => {
                      navigate(`/servicos?id=${business._id}`);
                    }}
                  >
                    Ver
                  </Button>
                </Flex>
              </Box>
            );
          })}
        </Flex>
        <Flex
          align="center"
          w="full"
          maxW="420px"
          px="5px"
          mt="10"
          direction="column"
          color="white"
        >
          <Text fontSize="sm" fontWeight="semibold">
            Baixe o app e descubra novos profissionais
          </Text>
          <Image
            mt="2"
            src="https://logodownload.org/wp-content/uploads/2017/04/disponivel-google-play-badge-4.png"
            h="60px"
            cursor="pointer"
            onClick={() => {
              window.location.replace(
                "https://play.google.com/store/apps/details?id=com.elevii.vizzua.users"
              );
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
