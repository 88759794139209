import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import React from "react";
import { Button } from "@chakra-ui/react";

const CaptchaButton = ({ onVerifyCaptcha }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const clickHandler = async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("contact");

    onVerifyCaptcha(token);
  };

  return (
    <Button colorScheme="blue" onClick={clickHandler}>
      Confirmar
    </Button>
  );
};

export const ReCaptcha = ({ onVerifyCaptcha }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LdvnbkjAAAAAK2fMcTMqKDrPqVEdw48-GmAlpj5">
    <CaptchaButton onVerifyCaptcha={onVerifyCaptcha} />
  </GoogleReCaptchaProvider>
);
