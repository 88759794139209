import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Input,
  FormControl,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import Calendar from "../components/calendarComponent/calendar";
import SiteRepository from "../repositories/site_repository";
import queryString from "query-string";
import { useLocation } from "react-router";
import format from "date-fns/format";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/react";
import { ReCaptcha } from "../services/recaptcha";

export default function AgendamentoPage() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [availableHours, setAvailableHours] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [business, setBusiness] = useState(null);
  const [service, setService] = useState(null);
  const [date, setDate] = useState(null);
  const [name, setNome] = useState(null);
  const [phone, setTelefone] = useState(null);
  const [address, setAddress] = useState(null);
  const [message, setMessage] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const toast = useToast();
  const toastIdRef = useRef();

  useEffect(() => {
    var today = new Date();
    const qs = queryString.parse(location.search);
    const serviceId = qs.id;
    const id = qs.business_id;
    setBusiness(id);
    setDate(today);

    fetchEmployees(id, serviceId);
  }, [loaded]);

  const openImage = (url) => {
    window.open(url, "Image", "width=700px,height=700px,resizable=1");
  };
  const updateDate = (dayStr) => {
    let newDate = new Date(
      dayStr.getFullYear(),
      dayStr.getMonth(),
      dayStr.getDate()
    );
    setSelectedHour(null);
    setDate(newDate);

    fetchAvailableHours(dayStr, service.duration, business, selectedEmployee);
  };

  const fetchAvailableHours = (date, duration, bId, e) => {
    var params = {
      date: date,
      duration: duration,
      business_id: bId,
      employee_id: e.user_id,
    };

    if (!e) setLoaded(true);

    SiteRepository.consultarAvailableHours({
      params,
    }).then((res) => {
      if (res.status === 200) {
        setAvailableHours(res.data);
      }
    });
  };

  const fetchEmployees = (bId, serviceId) => {
    fetchService(serviceId);
    var today = new Date();
    const qs = queryString.parse(location.search);
    const id = qs.business_id;
    var params = {
      business_id: bId,
    };

    SiteRepository.consultarEmployees({
      params,
    }).then((res) => {
      if (res.status === 200) {
        setEmployees(res.data);
        setSelectedEmployee(res.data[0]);
        setLoaded(true);
        if (!service) {
          setLoaded(false);
          fetchService(serviceId);
        }
        fetchAvailableHours(today, service.duration, id, res.data[0]);
      }
    });
  };

  const fetchService = (id) => {
    var params = {
      service_id: id,
    };

    SiteRepository.consultarService({
      params,
    }).then((res) => {
      if (res.status === 200) {
        setService(res.data);
        setLoaded(true);
      }
    });
  };

  const createSchedule = () => {
    const qs = queryString.parse(location.search);
    var params = {
      user_name: name,
      message: message,
      date: date,
      user_phone: phone,
      user_address: address,
      employee_id: selectedEmployee.user_id,
      service_id: qs.id,
      selected_hour: selectedHour,
      business_id: business,
      source: "user_web_app",
    };

    SiteRepository.criarSchedule({
      params,
    }).then((res) => {
      if (res.status === 200) {
        navigate(`/resumo-agendamento?id=${res.data}`);
      } else {
        onClose();
        updateDate(date);
        toastIdRef.current = toast({
          description: "Esse horário foi agendado a poucos instantes:/",
          status: "error",
        });
      }
    });
  };

  const confirmSchedule = () => {
    if (!name || !phone || (service.business_id.at_home && !address)) {
      toastIdRef.current = toast({
        description: "Você precisa preencher todos os campos",
        status: "error",
      });
      return;
    } else {
      createSchedule();
    }
  };

  const onVerifyCaptcha = (token) => {
    // var params = {
    //   secret: "6LdvnbkjAAAAAM84Gvafse7MFXclZ4DWrair9xy4",
    //   response: token,
    // };
    // SiteRepository.validarToken({
    //   params,
    // }).then((res) => {
    //    if (res.status === 200) {
    //       confirmSchedule();
    //     } else {
    //       console.log(res, "res");
    //     }
    // });
    // axios
    //   .post(
    //     `https://www.google.com/recaptcha/api/siteverify?secret=6LdvnbkjAAAAAM84Gvafse7MFXclZ4DWrair9xy4&response=${token}`,
    //     {
    //       headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //       validateStatus: function () {
    //         return true;
    //       },
    //     }
    //   )
    //   .then((res) => {});
    confirmSchedule();

    // setValue("captchaToken", token);
  };

  if (!service) return <></>;

  return (
    <>
      <Box align="center" backgroundColor="#5D34BE" h="-webkit-fit-content">
        <Flex align="center" direction="column" maxW="500px" mx="5" pt="2">
          <Box
            h={["240px", "240px"]}
            w="full"
            backgroundColor={["#5D34BE", "#683dcc"]}
            borderRadius="8"
            mt="5"
            mb="2"
          >
            <Image
              onClick={[
                () => {},
                () => {
                  openImage(
                    service.photo_url ?? "https://i.imgur.com/99HaG7b.png"
                  );
                },
              ]}
              src={service.photo_url ?? "https://i.imgur.com/99HaG7b.png"}
              h="100%"
              w={["300px", "240px"]}
              objectFit="cover"
              borderRadius="8"
            />
          </Box>
          <Flex
            mt="2"
            px="2"
            color="white"
            direction="row"
            align="start"
            w="100%"
            justifyContent="space-between"
          >
            <Flex direction="column" align="start">
              <Text fontWeight="semibold" fontSize="lg">
                {service.name}
              </Text>
              <Text fontSize="sm" noOfLines="2" textAlign="start">
                {service.description}
              </Text>
            </Flex>
          </Flex>
          <Divider w="30px" mb="4" />
          <Calendar showDetailsHandle={updateDate} />

          <Flex
            mt="2"
            mb="2"
            px="2"
            color="white"
            direction="row"
            align="start"
            w="full"
            justifyContent="space-between"
          >
            <Flex direction="column" align="start">
              <Text fontWeight="semibold" fontSize="md">
                Colaboradores
              </Text>
              <Flex direction="row" gap="2">
                {employees.map((employee, index, arr) => {
                  return (
                    <Flex
                      direction="column"
                      align="center"
                      onClick={() => {
                        setSelectedEmployee(employee);
                      }}
                      maxW="100px"
                      noOfLines="2"
                      overflow="ellipsis"
                      cursor="pointer"
                    >
                      <Avatar
                        borderWidth={selectedEmployee === employee ? 2 : 0}
                        name={employee.user.name
                          .split(" ")
                          .slice(0, 2)
                          .join(" ")}
                        src={employee.user.photo_source ?? ""}
                      />
                      <Text fontSize="xs">
                        {employee.user.name.split(" ").slice(0, 2).join(" ")}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>

          <Flex
            mt="2"
            mb="4"
            px="2"
            pb="5"
            color="white"
            direction="row"
            align="start"
            w="full"
            h="100px"
            maxW="500px"
            overflowX="scroll"
            justifyContent="space-between"
          >
            <Flex direction="column" align="start">
              <Text fontWeight="semibold" fontSize="md">
                Horários disponíveis
              </Text>
              <Flex direction="row" gap="2">
                {availableHours.length === 0 && (
                  <Text color="whiteAlpha.400">
                    Não há horários disponíveis
                  </Text>
                )}
                {availableHours.map((hour, index, arr) => {
                  return (
                    <Button
                      colorScheme={selectedHour === hour ? "green" : "purple"}
                      onClick={() => {
                        let hora = hour.split(":").slice(0, 1);
                        let minuto = hour.split(":").slice(1, 2);
                        let newDate = new Date(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate(),
                          hora,
                          minuto,
                          0
                        );

                        setDate(newDate);
                        setSelectedHour(hour);
                      }}
                    >
                      {hour}
                    </Button>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>

          <Flex
            mt="2"
            mb="2"
            px="2"
            pb="5"
            color="white"
            direction="row"
            align="start"
            w="full"
            maxW="500px"
            justifyContent="space-between"
          >
            <Textarea
              colorScheme="whiteAlpha"
              placeholder="Alguma observação?"
              focusBorderColor="white"
              borderColor="#f8d6ff"
              borderWidth="0.2px"
              onChange={(e) => setMessage(e.target.value)}
            />
          </Flex>

          <Flex
            mt="2"
            mb="4"
            px="2"
            color="white"
            direction="row"
            align="center"
            w="full"
            maxW="500px"
            justifyContent="space-between"
          >
            <Flex direction="column" lineHeight="17px" align="start" w="120px">
              <Text fontSize="md" fontWeight="bold">
                R$ {service.price}
              </Text>
              <Text fontSize="xs">{service.duration} minutos</Text>
            </Flex>
            <Button
              colorScheme="whiteAlpha"
              w="full"
              h="50px"
              onClick={() => {
                if (selectedHour) {
                  onOpen();
                } else {
                  toastIdRef.current = toast({
                    description: "Você precisa selecionar um horário",
                    status: "error",
                  });
                }
              }}
            >
              Agendar
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#292b2a">Confirmação de agendamento</ModalHeader>
          <ModalCloseButton />
          <ModalBody color="#292b2a">
            <form>
              <Text fontWeight="semibold">Nome</Text>
              <FormControl isRequired>
                <Input
                  placeholder="Informe seu nome"
                  onChange={(e) => setNome(e.target.value)}
                />
              </FormControl>
              <Text fontWeight="semibold">Telefone</Text>
              <FormControl isRequired>
                <Input
                  placeholder="Informe seu telefone"
                  onChange={(e) => setTelefone(e.target.value)}
                />
              </FormControl>

              {service.business_id.at_home && (
                <Flex direction="column">
                  <Text fontWeight="semibold">Endereço</Text>
                  <FormControl isRequired>
                    <Textarea
                      placeholder="Informe seu endereço"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </FormControl>
                </Flex>
              )}
              <Divider my="5" />
              {!service.business_id.at_home && (
                <Flex direction="column">
                  <Text fontWeight="semibold">Endereço</Text>
                  <Text>{service.business_id.full_address}</Text>
                </Flex>
              )}

              <Text fontWeight="semibold">Empresa</Text>
              <Text>{service.business_name}</Text>
              <Text fontWeight="semibold">Serviço</Text>
              <Text>{service.name}</Text>

              <Text fontWeight="semibold">Preço</Text>
              <Text>{"R$ " + service.price}</Text>
              <Text fontWeight="semibold">Data</Text>
              <Text>{format(date, "dd/MM/yyyy - HH:mm")}</Text>
            </form>
          </ModalBody>

          <ModalFooter>
            <ReCaptcha onVerifyCaptcha={onVerifyCaptcha} />
            {/* <Button
              colorScheme="blue"
              mr={3}
              mt="2"
              onClick={() => {
                confirmSchedule();
              }}
            >
              Confirmar
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
