import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppRoutes from "./Routes";
import moment from "moment-timezone";

import setDefaultOptions from "date-fns/setDefaultOptions";
import { ptBR } from "date-fns/locale";
setDefaultOptions({ locale: ptBR });
moment.tz.setDefault("America/Sao_Paulo");

export default function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}
