import api from "../services/api";

const consultarNearbyBusiness = async (params) =>
  api.get("api/v1/public/get-by-location", params);

const consultarBusinessServices = async (params) =>
  api.get("api/v1/public/business-services", params);

const consultarAvailableHours = async (params) =>
  api.get("api/v1/public/get-available-hours", params);

const consultarEmployees = async (params) =>
  api.get("api/v1/public/get-employees", params);

const consultarService = async (params) =>
  api.get("api/v1/public/get-service", params);

const consultarSchedule = async (params) =>
  api.get("api/v1/public/get-schedule", params);

const criarSchedule = async (params) =>
  api.post("api/v1/public/create-schedule", params);

  const cancelSchedule = async (params) =>
  api.post("api/v1/public/cancel-schedule", params);

const validarToken = async (params) =>
  api.post("https://www.google.com/recaptcha/api/siteverify", params);

const methods = {
  consultarNearbyBusiness,
  consultarBusinessServices,
  consultarAvailableHours,
  consultarEmployees,
  consultarService,
  consultarSchedule,
  criarSchedule,
  cancelSchedule,
  validarToken,
};

export default methods;
